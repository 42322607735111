import React, {useEffect, useState} from "react";
import Table from "../../../atoms/Table";
import {useDispatch, useSelector} from "react-redux";
import {isActiveModal, setModal} from "../../../../modules/actions/modalAction";
import UserCreateModal from "../userCreateModal";
import axiosInstance from "../../../../modules/util/axiosInstance";

const UserListTable = () => {
    const modal = useSelector(status => status.isActiveModal);
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0)
    const dispatch = useDispatch();
    const [userList, setUserList] = useState([]);

    // 유저정보 가져오기
    useEffect(() => {
        try {
            axiosInstance.get('/api/member'
            ).then((e) => {
                const responseData = e.data.data;
                setUserList(responseData?.itemList);
                setTotalPage(responseData?.total);
            })
        } catch (e) {

        }
    }, [modal, page]);

    const openModal = (data) => {
        dispatch(isActiveModal());
        dispatch(setModal({
            title: "유저 수정",
            component: <UserCreateModal User={{id: data.loginId, name: data.username}} update={true}/>
        }));
    }

    const headerData = ["order", "id", "email", "role", "action"];
    const cellData = userList.map((data, idx) => {
        return (
            <>
                <td style={{width: "5%"}}>{idx + 1}</td>
                <td>{data.loginId}</td>
                <td>{data.username}@lloydk.co.kr</td>
                <td>
                    {
                        {
                            "admin": <i className="ri-admin-fill"/>,
                            "user": <i className="ri-user-fill"/>
                        }[data.role]
                    }
                </td>
                <td onClick={() => openModal(data)}>
                    <i className="ri-settings-2-fill"/>
                </td>
            </>
        )
    });

    return (
        <>
            <div className="totalCount">
                total {totalPage}
            </div>
            <Table headerData={headerData} cellData={cellData}/>

            <div className="table_page">
                {
                    [...Array(Math.ceil(totalPage / 20))].map((data, idx) => {
                        return <button style={(idx === page) ? {backgroundColor: "#175fC7" , color: "white"} : {}} key={"tablePageBtn_" + idx} onClick={() => setPage(idx)}>{idx + 1}</button>
                    })
                }
            </div>
        </>
    )
}
export default UserListTable;