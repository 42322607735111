import React from 'react';
import {useDispatch} from "react-redux";
import {isActiveModal, setModal} from "../../../modules/actions/modalAction";
import UserListTable from "../../molecules/user/userListTable";
import UserCreateModal from "../../molecules/user/userCreateModal";

const UserList = () => {
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModal({
            title: "유저 생성",
            size: {
                width: 30,
                height: 50
            },
            component: <UserCreateModal User={{}} update={false}/>
        }))
        dispatch(isActiveModal());
    }

    return (
        <div className="userList">
            <h2>UserList</h2>
            <div className="addUser">
                <i className="ri-user-add-line" onClick={() => openModal()}></i>
            </div>
            <div className="userListTable">
                <UserListTable/>
            </div>
        </div>
    )
}

export default UserList;